module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lowympact","short_name":"Lowympact","start_url":"/","icon":"src/Assets/images/pwa-192x192.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f958ec621436acb936ec702f24bf9f69"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-ES6GXLCZ0M","head":false,"anonymize":false,"respectDNT":false,"exclude":["/preview/**"],"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
